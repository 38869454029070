export function str_after(subject, search = '') {
    return !search ? subject : subject.split(search, 2).reverse()[0];
}

export function str_ucfirst(subject) {
    return subject[0].toUpperCase() + subject.substring(1);
}

export function str_lcfirst(subject) {
    return subject[0].toLowerCase() + subject.substring(1);
}

export function str_studly(subject) {
    const key = subject;

    const words = subject.split(/[_\-]/);

    return words.map((word) => str_ucfirst(word)).join('');
}

export function str_camel(subject) {
    return str_lcfirst(str_studly(subject));
}
